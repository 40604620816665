<template>
    <div class="box">
        <div class="box-header with-border">
            <h3>
                <previous-route-block/>

                {{ page_title }}

                <button class="btn btn-primary pull-right margin-l-5" title="Обновить sitemap.xml" type="button"
                        v-on:click="generateSitemap">
                    <i class="fa fa-refresh"></i> Обновить Sitemap
                </button>

                <a :href="$clientAppUrl + '/sitemap.xml'" class="btn btn-info pull-right"
                   title="Открыть sitemap.xml" target="_blank">
                    <i class="fas fa-sitemap"></i>
                </a>
            </h3>
        </div>

        <div class="box-body">
            <table-items :data_search="data_search"
                         :relations="relations"
                         component="seo-item"
                         :columns="columns"
                         api_resource="/seo_items">
            </table-items>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import TableItems from './../../components/pagination/table-items';

    Vue.component('seo-item', (resolve) => {
        return require(['./item'], resolve);
    });

    export default {
        name: 'seo_items',

        components: {
            TableItems,
        },

        props: {
            data_search: {
                type: String,
                required: false
            }
        },

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        data: () => ({
            page_title: 'SEO-данные',

            columns: [
                {
                    title: 'Страница',
                    class: null,
                    style: null,
                },
                {
                    title: 'SEO-title',
                    class: null,
                    style: null,
                },
                {
                    title: 'SEO-description',
                    class: null,
                    style: null,
                },
                {
                    title: 'Заголовок страницы',
                    class: null,
                    style: null,
                },
                {
                    title: 'Обновлён',
                    class: null,
                    style: null,
                },
                {
                    title: null,
                    class: null,
                    style: null,
                },
            ],
            relations: [
                'editor',
            ],
        }),

        methods: {
            generateSitemap() {
                this.$dialog.confirm('Вы уверены что хотите выполнить действие?', this.$dialogOptions).then(() => {
                    API.apiClient.put('/seo_items/sitemap/generate').then(() => {
                        showSuccess();
                    }).catch((error) => {
                        errorHandler(error);
                    });
                });
            },
        },
    }
</script>

<style scoped>

</style>
